import { useCallback } from "react";

import {
  LocalStorageKeys,
  useLocalStorageSetter,
} from "../../hooks/useLocalStorage.js";
import { COLLAPSED_SIDEBAR_WIDTH } from "../sidebar/SidebarOptions.js";

export function useInitialProjectConfiguration(): () => void {
  const setGraphOpen = useLocalStorageSetter(LocalStorageKeys.GRAPH_OPEN);
  const setSidebarCollapsed = useLocalStorageSetter(
    LocalStorageKeys.OUTLINE_COLLAPSED,
  );
  const setSidebarWidth = useLocalStorageSetter(LocalStorageKeys.SIDEBAR_WIDTH);

  const setInitialProjectConfiguration = useCallback(() => {
    setGraphOpen(false);

    setSidebarWidth(COLLAPSED_SIDEBAR_WIDTH);
    setSidebarCollapsed(true);
  }, [setGraphOpen, setSidebarWidth, setSidebarCollapsed]);

  return setInitialProjectConfiguration;
}
