import { encode } from "querystring";

import { FormGroup, Intent } from "@blueprintjs/core";
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE_JSON,
  DOCS_LINKS,
  SignupErrorReason,
  withAttribution,
} from "@hex/common";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import styled from "styled-components";

import { HexButton, HexCallout, HexInputGroup } from "../../hex-components";
import { useAttribution } from "../../hooks/useAttribution";
import { useToggleState } from "../../hooks/useToggleState";
import {
  AlertBody,
  AlertHeader,
  ErrorContainer,
  Link,
  TosBlurb,
} from "../../route/LoginRoute";
import { QueryParams, Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { isMagicLinkEnabled } from "../../util/data";
import { createRequestHeaders } from "../../util/headers";
import {
  maybeIncludeTelemetry,
  useIsTelemetryEnabled,
} from "../../util/telemetry";
import { useHexFlag } from "../../util/useHexFlags";
import {
  EmailFormHeader,
  LoginButtonContainer,
  LoginCardContainer,
  LoginCardLoading,
} from "../login/LoginCard";

import { AzureSignup } from "./AzureSignup";
import { GoogleSignup } from "./GoogleSignup";

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const SignupErrorContainer = styled(ErrorContainer)`
  margin-bottom: 0px;
`;

const SignupFormGroup = styled(FormGroup)`
  flex: auto;
`;

const LoginCTA = styled.div`
  display: flex;

  gap: 5px;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
`;

interface SignupAuthnContainerProps {
  failed?: SignupErrorReason | null;
  invited?: boolean;
  authQueryParams?: QueryParams;
  defaultSubHeader?: JSX.Element;
}

/**
 * Component that handles all authentication component specifically
 * for signup.
 */
export const SignupAuthnContainer: React.ComponentType<SignupAuthnContainerProps> =
  React.memo(function SignupAuthnContainer({
    authQueryParams = {},
    defaultSubHeader = null,
    failed,
    invited,
  }) {
    const attribution = useAttribution();
    const formRef = useRef<HTMLFormElement>(null);
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [userSubmitted, , { setTrue: authnRequested }] =
      useToggleState(false);

    const telemetryTimeoutMs = useHexFlag("telemetry-timeout");
    const isTelemetryEnabled = useIsTelemetryEnabled();
    const verbCopy = invited ? "Join" : "Continue";

    const onEmailChange = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        setEmail(evt.currentTarget.value);
      },
      [],
    );
    const onNameChange = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        setName(evt.currentTarget.value);
      },
      [],
    );

    // We only need to submit name for Magic Link requests
    const canSubmit =
      !userSubmitted &&
      (isMagicLinkEnabled ? name.length && email.length : email.length);

    const onMagicSubmit = useCallback(
      async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        authnRequested();
        let res;
        let res_json;
        try {
          res = await fetch(
            withAttribution(
              `/auth/magic/signup?${encode(authQueryParams)}`,
              attribution,
            ),
            {
              method: `POST`,
              body: JSON.stringify(
                await maybeIncludeTelemetry(
                  {
                    email,
                    name,
                  },
                  isTelemetryEnabled,
                  telemetryTimeoutMs,
                ),
              ),
              headers: {
                [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE_JSON,
                ...createRequestHeaders(),
              },
            },
          );
          res_json = await res.json();
        } catch (_) {
          // If anything goes horrifically wrong while trying to POST, just
          // submit the form so they get kicked to Typeform.
          formRef.current?.submit();
          return;
        }

        const { code, error, redirectTo, success } = res_json;
        if (success) {
          window.location.href = `/signup${Routes.MAGIC_LINK_SENT.getUrl({
            attribution,
            code,
            email,
          })}`;
        } else if (redirectTo) {
          // If we're specifically provided a redirectTo from the POST, kick to
          // the URL.
          window.location.href = withAttribution(redirectTo, attribution);
        } else {
          // TODO(VELO-4769): Potentially update wording and remove this once
          // we allow all public emails onto the website.
          // Otherwise, we've likely been threaded a failure. If isPublic is
          // set, we know it's a domain issue. If isSingleTenant is set, we know
          // it's a single-tenant issue. Otherwise we just treat it as a generic
          // unknown problem. Details will be logged on the back end.
          const reason = error.isPublic
            ? SignupErrorReason.DOMAIN_CONSTRAINT
            : error.isSingleTenant
              ? SignupErrorReason.SINGLE_TENANT
              : SignupErrorReason.GENERIC;
          window.location.href = withAttribution(
            `/signup?failed=${reason}`,
            attribution,
          );
        }
      },
      [
        authQueryParams,
        email,
        name,
        isTelemetryEnabled,
        authnRequested,
        telemetryTimeoutMs,
        attribution,
      ],
    );

    // Google SSO is not supported in iOS WebViews
    const googleSsoSupported = !(
      /linkedin|instagram|twitter|messengerforios/i.test(
        navigator.userAgent.toLowerCase(),
      ) && navigator.userAgent.includes("iPhone")
    );
    return (
      <div
        css={`
          display: flex;
          flex-direction: column;
          gap: 30px;
        `}
      >
        {failed === SignupErrorReason.INVITE_LINK ? (
          <SignupErrorContainer>
            <AlertHeader>Oops!</AlertHeader>
            <AlertBody>That invite is invalid or expired.</AlertBody>
          </SignupErrorContainer>
        ) : failed === SignupErrorReason.DOMAIN_CONSTRAINT ? (
          <SignupErrorContainer>
            <AlertHeader>Oops! That looks like a personal email.</AlertHeader>
            <AlertBody>
              Please sign up with your work email, or{" "}
              <Link href="https://hextech.typeform.com/to/YXVqshBP">
                join our waitlist.
              </Link>
            </AlertBody>
          </SignupErrorContainer>
        ) : failed === SignupErrorReason.SINGLE_TENANT ? (
          <SignupErrorContainer>
            <AlertHeader>Oops!</AlertHeader>
            <AlertBody>
              You already have a dedicated workspace. Please reach out to your
              company&apos;s Hex admin for access.
            </AlertBody>
          </SignupErrorContainer>
        ) : failed === SignupErrorReason.MAX_SIGNUPS ? (
          <SignupErrorContainer>
            <AlertHeader>Oops!</AlertHeader>
            <AlertBody>
              You already have a workspace. Head over to{" "}
              <Link
                href={Routes.LOGIN.getUrl({
                  attribution,
                  params: {
                    newSignIn: "true",
                  },
                })}
              >
                log in
              </Link>{" "}
              to get started.
            </AlertBody>
          </SignupErrorContainer>
        ) : failed != null ? (
          <SignupErrorContainer>
            <AlertHeader>Oops!</AlertHeader>
            <AlertBody>Something went wrong. Please try again.</AlertBody>
          </SignupErrorContainer>
        ) : (
          defaultSubHeader
        )}
        {!googleSsoSupported && (
          <HexCallout $size="small" intent="warning">
            Looks like you&apos;re in an in-app web browser. For the best
            experience we recommend navigating to app.hex.tech/signup from your
            device&apos;s browser application (e.g. Chrome).
          </HexCallout>
        )}
        <LoginCardContainer
          css={`
            position: relative;
          `}
        >
          {(!failed || failed !== SignupErrorReason.GENERIC) && (
            <>
              {googleSsoSupported && (
                <LoginButtonContainer>
                  <GoogleSignup
                    authQueryParams={authQueryParams}
                    onSubmit={authnRequested}
                  >
                    {verbCopy} with <span>Google</span>
                  </GoogleSignup>
                </LoginButtonContainer>
              )}
              <LoginButtonContainer>
                <AzureSignup
                  authQueryParams={authQueryParams}
                  onSubmit={authnRequested}
                >
                  {verbCopy} with <span>Microsoft</span>
                </AzureSignup>
              </LoginButtonContainer>
              <EmailFormHeader>
                <span>Or continue with email</span>
              </EmailFormHeader>
            </>
          )}
          <SignupForm
            ref={formRef}
            action="https://hextech.typeform.com/to/YXVqshBP"
            method="get"
            onSubmit={isMagicLinkEnabled ? onMagicSubmit : undefined}
          >
            {isMagicLinkEnabled && (
              <SignupFormGroup labelFor="name">
                <HexInputGroup
                  data-cy={CyData.SIGNUP_NAME}
                  id="name"
                  name="name"
                  placeholder="First and last name"
                  required={true}
                  value={name}
                  onChange={onNameChange}
                />
              </SignupFormGroup>
            )}
            <SignupFormGroup labelFor="email">
              <HexInputGroup
                data-cy={CyData.SIGNUP_EMAIL}
                id="email"
                name="email"
                placeholder="you@work-email.com"
                required={true}
                type="email"
                value={email}
                onChange={onEmailChange}
              />
            </SignupFormGroup>
            <HexButton
              data-cy={CyData.SIGNUP_SUBMIT}
              disabled={!canSubmit}
              intent={Intent.PRIMARY}
              type="submit"
            >
              {invited ? "Join workspace" : "Continue"}
            </HexButton>
          </SignupForm>
          {userSubmitted && <LoginCardLoading />}
        </LoginCardContainer>
        <LoginCTA>
          Returning user?
          <Link
            href={Routes.LOGIN.getUrl({ attribution, params: authQueryParams })}
          >
            Log in
          </Link>
        </LoginCTA>
        <TosBlurb
          css={`
            margin: 0px;
          `}
        >
          By signing up, you agree to our{" "}
          <Link href={DOCS_LINKS.Terms} target="_blank">
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link href={DOCS_LINKS.Privacy} target="_blank">
            Privacy Policy.
          </Link>
        </TosBlurb>
      </div>
    );
  });
