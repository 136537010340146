import { Classes, Collapse, Intent } from "@blueprintjs/core";
import { HexType } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { HexButton, HexCallout } from "../../hex-components";
import { useToggleState } from "../../hooks/useToggleState";
import { WarningsFragment } from "../../mutations/import.generated";

export interface ImportProjectWarningsProps {
  warnings: WarningsFragment;
  dialog: "project-import" | "version-import";
  closeDialog: () => void;
}

const Toggle = styled.span`
  color: ${({ theme }) => theme.intent.PRIMARY};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled.div`
  margin-top: 10px;
`;

interface WarningSectionProps {
  primaryMessage: React.ReactNode;
  collapsedMessage?: React.ReactNode;
  warningItems?: readonly string[];
}

const WarningSection: React.ComponentType<WarningSectionProps> = React.memo(
  function WarningSection({
    collapsedMessage,
    primaryMessage,
    warningItems,
  }: WarningSectionProps) {
    const [warningOpen, , { toggle: toggleWarning }] = useToggleState(false);

    return (
      <li>
        {primaryMessage}{" "}
        {collapsedMessage && (
          <Toggle onClick={toggleWarning}>
            {warningOpen ? "Less" : "More"} detail
          </Toggle>
        )}
        <Collapse isOpen={warningOpen}>
          <HexCallout>
            {collapsedMessage}
            {warningItems && (
              <ul>
                {warningItems.map((value, idx) => (
                  <li key={idx}>{value}</li>
                ))}
              </ul>
            )}
          </HexCallout>
        </Collapse>
      </li>
    );
  },
);

export const ImportProjectWarnings: React.ComponentType<ImportProjectWarningsProps> =
  React.memo(function ImportProjectWarnings({
    closeDialog,
    dialog,
    warnings,
  }: ImportProjectWarningsProps) {
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <HexCallout intent={Intent.WARNING}>
            Your project was successfully loaded, but some elements were not
            imported.
          </HexCallout>
          <Header>Your project file has:</Header>
          <ul>
            {warnings.status && (
              <WarningSection
                collapsedMessage={
                  <>
                    {dialog === "project-import"
                      ? `This means either you're importing a project from a different workspace, or the status "${warnings.status}" has been deleted or renamed.`
                      : `This means the status "${warnings.status}" has been deleted or renamed since you exported this project.`}{" "}
                    Try updating your project to use a valid status.
                  </>
                }
                primaryMessage={
                  <>
                    the status <b>{warnings.status}</b>, which does not exist in
                    this workspace.
                  </>
                }
              />
            )}
            {warnings.categories && warnings.categories.length > 0 && (
              <WarningSection
                collapsedMessage={
                  <>
                    {dialog === "project-import"
                      ? `This means either you're importing a project from a different workspace, or these categories have been deleted or renamed.`
                      : `This means these categories have been deleted or renamed since you exported this project.`}{" "}
                    Try updating your project to use valid categories. Missing
                    categories:
                  </>
                }
                primaryMessage={
                  <>
                    <b>
                      {warnings.categories.length} categor
                      {warnings.categories.length === 1 ? "y" : "ies"}
                    </b>{" "}
                    that do{warnings.categories.length === 1 ? "es" : ""} not
                    exist in this workspace.
                  </>
                }
                warningItems={warnings.categories}
              />
            )}
            {warnings.projectTitle && (
              <WarningSection
                collapsedMessage="The project title can only be modified via the Hex UI."
                primaryMessage={
                  <>
                    the modified project title <b>{warnings.projectTitle}</b>
                  </>
                }
              />
            )}
            {warnings.projectLanguage && (
              <WarningSection
                collapsedMessage="You cannot change the code language for an existing project."
                primaryMessage={
                  <>
                    the modified project code language{" "}
                    <b>{warnings.projectLanguage}</b>
                  </>
                }
              />
            )}
            {warnings.projectSecrets && warnings.projectSecrets.length > 0 && (
              <WarningSection
                collapsedMessage={
                  dialog === "project-import"
                    ? `Hex project files do not contain sensitive information like secret values. Reconfigure the following secrets to use in this project:`
                    : `The following secrets were referenced in your project file, but could not be found in the target project:`
                }
                primaryMessage={
                  <>
                    <b>
                      {warnings.projectSecrets.length} project secret
                      {warnings.projectSecrets.length === 1 ? "" : "s"}
                    </b>{" "}
                    that could not be imported.
                  </>
                }
                warningItems={warnings.projectSecrets}
              />
            )}
            {warnings.sharedSecrets && warnings.sharedSecrets.length > 0 && (
              <WarningSection
                collapsedMessage={
                  dialog === "project-import"
                    ? `This means either you're importing a project from a different workspace, or these secrets have been deleted or renamed.`
                    : `This means these secrets have been deleted or renamed since you exported this project.`
                }
                primaryMessage={
                  <>
                    <b>
                      {warnings.sharedSecrets.length} shared secret
                      {warnings.sharedSecrets.length === 1 ? "" : "s"}
                    </b>{" "}
                    that could not be imported.
                  </>
                }
                warningItems={warnings.sharedSecrets}
              />
            )}
            {warnings.projectDataConnections &&
              warnings.projectDataConnections.length > 0 && (
                <WarningSection
                  collapsedMessage={
                    dialog === "project-import"
                      ? `Hex project files do not contain sensitive information like data connection configuration. Reconfigure the following data connections to use in this project:`
                      : `The following data connections were referenced in your project file, but could not be found in the target project:`
                  }
                  primaryMessage={
                    <>
                      <b>
                        {warnings.projectDataConnections.length} project data
                        connection
                        {warnings.projectDataConnections.length === 1
                          ? ""
                          : "s"}
                      </b>{" "}
                      that could not be imported.
                    </>
                  }
                  warningItems={warnings.projectDataConnections}
                />
              )}
            {warnings.sharedDataConnections &&
              warnings.sharedDataConnections.length > 0 && (
                <WarningSection
                  collapsedMessage={
                    dialog === "project-import"
                      ? `This means either you're importing a project from a different workspace, or these data connections have been deleted.`
                      : `This means these data connections have been deleted since you exported this project.`
                  }
                  primaryMessage={
                    <>
                      <b>
                        {warnings.sharedDataConnections.length} shared data
                        connection
                        {warnings.sharedDataConnections.length === 1 ? "" : "s"}
                      </b>{" "}
                      that could not be imported.
                    </>
                  }
                  warningItems={warnings.sharedDataConnections}
                />
              )}
            {warnings.sharedVcsPackages &&
              warnings.sharedVcsPackages.length > 0 && (
                <WarningSection
                  collapsedMessage={
                    dialog === "project-import"
                      ? `This means either you're importing a project from a different workspace, or these Git packages have been deleted.`
                      : `This means these Git packages have been deleted since you exported this project.`
                  }
                  primaryMessage={
                    <>
                      <b>
                        {warnings.sharedVcsPackages.length} shared GitHub
                        package
                        {warnings.sharedVcsPackages.length === 1 ? "" : "s"}
                      </b>{" "}
                      that could not be imported.
                    </>
                  }
                  warningItems={warnings.sharedVcsPackages}
                />
              )}
            {warnings.exportedHexType &&
              warnings.exportedHexType === HexType.COMPONENT && (
                <WarningSection
                  primaryMessage={
                    <>
                      been exported as a <b>component</b> but it was imported as
                      a project.
                    </>
                  }
                />
              )}
            {dialog === "version-import" &&
              warnings.cellIds &&
              warnings.cellIds.length > 0 && (
                <WarningSection
                  collapsedMessage={
                    "The cells with the following IDs could not be matched up with existing cells in the target project. This can be caused by " +
                    "manually editing cell IDs in the project file. We imported them anyway, but this can cause comments to be lost and may cause other issues."
                  }
                  primaryMessage={
                    <>
                      <b>
                        {warnings.cellIds.length}{" "}
                        {`cell${warnings.cellIds.length === 1 ? "" : "s"}`}
                      </b>{" "}
                      that could not be matched to any existing cells.
                    </>
                  }
                  warningItems={warnings.cellIds}
                />
              )}
            {warnings.appLayout && warnings.appLayout.length > 0 && (
              <WarningSection
                collapsedMessage="These issues were detected:"
                primaryMessage={
                  <>
                    invalid <b>app layout</b> configuration.
                  </>
                }
                warningItems={warnings.appLayout}
              />
            )}
            {warnings.timezones && warnings.timezones.length > 0 && (
              <WarningSection
                collapsedMessage="These invalid timezone names were replaced with a default value:"
                primaryMessage={
                  <>
                    <b>
                      {warnings.timezones.length} invalid timezone
                      {warnings.timezones.length === 1 ? "" : "s"}
                    </b>{" "}
                    found.
                  </>
                }
                warningItems={warnings.timezones}
              />
            )}
          </ul>
        </div>
        <div
          className={Classes.DIALOG_FOOTER}
          css={`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            &&&&.${Classes.DIALOG_FOOTER} {
              padding: 20px;
            }
          `}
        >
          <HexButton
            intent={Intent.PRIMARY}
            text="Okay, got it"
            onClick={closeDialog}
          />
        </div>
      </>
    );
  });
