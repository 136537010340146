import { Tooltip2Props } from "@blueprintjs/popover2";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components";

import { UserAvatar, UserProps } from "./UserAvatar";

const TooltipContentDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;

  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const TooltipMetaDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
`;

const TooltipNameDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const TooltipEmailDiv = styled.div`
  margin-top: 3px;
  padding-bottom: 2px;

  line-height: 10px;
`;

export interface UserTooltipProps extends UserProps {
  children?: ReactNode;
  tooltipRenderTarget?: Tooltip2Props["renderTarget"];
  toolTipTargetTagName?: Tooltip2Props["targetTagName"];
}

/**
 * Display user information + avatar in a tool tip.
 */
export const UserTooltip: React.ComponentType<UserTooltipProps> = React.memo(
  function UserTooltip({
    children,
    toolTipTargetTagName,
    tooltipRenderTarget,
    ...userProps
  }) {
    return (
      <HexTooltip
        content={
          <TooltipContentDiv>
            <UserAvatar {...userProps} tooltip={false} />
            <TooltipMetaDiv>
              {userProps.name && (
                <TooltipNameDiv>{userProps.name}</TooltipNameDiv>
              )}
              {userProps.email && (
                <TooltipEmailDiv>{userProps.email}</TooltipEmailDiv>
              )}
            </TooltipMetaDiv>
          </TooltipContentDiv>
        }
        renderTarget={tooltipRenderTarget}
        targetTagName={toolTipTargetTagName}
      >
        {children}
      </HexTooltip>
    );
  },
);
