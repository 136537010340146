import { ProjectLanguage, SpecialVersionType } from "@hex/common";
import { useCallback } from "react";
import { useHistory } from "react-router";

import { Routes } from "../../route/routes";
import { useFeatureGates } from "../feature-gate/FeatureGateContext";
import { DEFAULT_PROJECT_TITLE } from "../logic/ProjectMetadata";

import { useCreateHexMutation } from "./NewProjectDialog.generated";
import { useInitialProjectConfiguration } from "./useInitialProjectConfiguration.js";

export interface UseCreateNewDefaultProjectResult {
  createPythonProject: (tourId?: string) => Promise<void>;
  createRProject: (tourId?: string) => Promise<void>;
}

interface UseCreateNewDefaultProjectOptions {
  throwError?: boolean;
  routingMode?: "replace" | "push";
}

export function useCreateNewDefaultProject({
  routingMode = "push",
  throwError,
}: UseCreateNewDefaultProjectOptions = {}): UseCreateNewDefaultProjectResult {
  const { projectLimit } = useFeatureGates();

  const history = useHistory();
  const [createHex] = useCreateHexMutation();

  const setInitialProjectConfiguration = useInitialProjectConfiguration();

  const createProject = useCallback(
    async (projectLanguage: ProjectLanguage, tourId?: string | undefined) => {
      try {
        const { data } = await createHex({
          variables: {
            name: DEFAULT_PROJECT_TITLE,
            projectLanguage,
            description: "",
            statusId: null,
            categoryIds: [],
            kernelImage: null,
            kernelSize: null,
            getProjectCount: projectLimit != null,
            collectionId: null,
            collectionProjectRole: null,
            isTour: tourId != null,
          },
        });
        if (data?.createHex?.id == null) {
          throw new Error("Creation failed");
        }
        setInitialProjectConfiguration();
        Routes[routingMode](history, Routes.LOGIC, {
          hexId: data.createHex.id,
          version: SpecialVersionType.DRAFT,
          urlParams: tourId ? { tour: tourId } : undefined,
        });
      } catch (e) {
        console.error(e);
        if (throwError) {
          throw e;
        }
      }
    },
    [
      createHex,
      projectLimit,
      setInitialProjectConfiguration,
      history,
      routingMode,
      throwError,
    ],
  );

  const createPythonProject = useCallback(
    async (tourId?: string) => {
      await createProject(ProjectLanguage.PYTHON, tourId);
    },
    [createProject],
  );
  const createRProject = useCallback(
    async (tourId?: string) => {
      await createProject(ProjectLanguage.R, tourId);
    },
    [createProject],
  );

  return { createPythonProject, createRProject };
}
